import { gql } from '@apollo/client/core';
import { markRaw } from 'vue';

export const createReferralLink = markRaw(gql`
  mutation Mutation(
    $code: String!
    $limit: Int
    $categoryId: String
    $restaurantId: String
  ) {
    createReferralLink(
      referralLink: {
        code: $code
        limit: $limit
        options: { categoryId: $categoryId, restaurantId: $restaurantId }
      }
    ) {
      code
      count
      id
      limit
      referralLink
      options {
        categoryId
        restaurantId
      }
      validUntil
    }
  }
`);

export const getReferralLinks = markRaw(gql`
  {
    getReferralLinks {
      code
      count
      id
      limit
      referralLink
      options {
        categoryId
        restaurantId
      }
      validUntil
    }
  }
`);

export const deleteReferralLink = markRaw(gql`
  mutation ($id: String!) {
    deleteReferralLink(id: $id)
  }
`);
