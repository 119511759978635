<template>
  <div class="referrals">
    <h1>{{ $t('referrals.header') }}</h1>
    <b-alert
      v-if="referralLinksError"
      variant="danger"
      dismissible
      @dismissed="referralLinksError = null"
    >
      {{ referralLinksError.message }}
    </b-alert>
    <b-alert
      v-if="createReferralLinkError"
      variant="danger"
      dismissible
      @dismissed="createReferralLinkError = null"
    >
      {{ createReferralLinkError.message }}
    </b-alert>
    <b-form-group>
      <b-form-group label-for="code">
        <label for="code">{{ $t('referrals.code') }}</label>
        <b-form-input id="code" v-model="code" required></b-form-input>
      </b-form-group>
      <b-form-group label-for="limit">
        <label for="limit">{{ $t('referrals.limit') }}</label>
        <b-form-input id="limit" type="number" v-model="limit"></b-form-input>
      </b-form-group>
      <b-form-group label-for="restaurant">
        <label for="restaurant">{{ $t('referrals.restaurant') }}</label>
        <Select2
          v-model="restaurantId"
          :options="
            restaurants?.getRestaurants.map((restaurant) => ({
              id: restaurant.id,
              text: restaurant.name,
            }))
          "
          :settings="{ closeOnSelect: true, selectOnClose: true }"
        />
      </b-form-group>
      <b-button variant="primary" @click="createReferral">{{
        $t('referrals.submit')
      }}</b-button>
    </b-form-group>
    <b-list-group>
      <b-list-group-item
        v-for="referral in referralLinks?.getReferralLinks"
        :key="referral.id"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div>
            {{ $t('referrals.code') }}: {{ referral.code }}<br />
            {{ $t('referrals.count') }}: {{ referral.count }}<br />
            {{ $t('referrals.restaurant') }}:
            {{
              restaurants?.getRestaurants.find(
                (rest) => rest.id === referral.options.restaurantId,
              )?.name || '-'
            }}
            <br />
            <a :href="`${referral.referralLink}`">{{
              referral.referralLink
            }}</a>
          </div>
          <b-button variant="danger" @click="deleteReferral(referral.id)">
            {{ $t('referrals.delete') }}
          </b-button>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script lang="ts">
import Select2 from 'v-select2-component';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { IReferralLink, IRestaurant } from 'types';
import { useRoute } from 'vue-router/composables';
import { rest } from 'lodash';

import {
  createReferralLink,
  deleteReferralLink,
  getReferralLinks,
  getRestaurants,
} from '../graphql';

export default {
  data() {
    return {
      code: '',
      limit: 0,
      restaurantId: '',
      categoryId: '',
    };
  },
  methods: {
    createReferral() {
      this.newReferralLink({
        code: this.code,
        limit: this.limit,
        restaurantId: this.restaurantId,
        categoryId: this.categoryId,
      });
    },
    deleteReferral(id: string) {
      this.deleteReferralLinkMutation({
        id,
      });
    },
  },
  setup() {
    const {
      result: referralLinks,
      loading: referralLinksLoading,
      error: referralLinksError,
      refetch: reloadLinks,
    } = useQuery<{ getReferralLinks: IReferralLink[] }>(getReferralLinks);
    const {
      result: restaurants,
      loading: restaurantsLoading,
      error: restaurantsError,
    } = useQuery<{ getRestaurants: IRestaurant[] }>(getRestaurants);
    const { mutate: newReferralLink, error: createReferralLinkError } =
      useMutation<{
        createReferralLink: IReferralLink;
      }>(createReferralLink, () => ({
        update: (cache, { data }) => {
          reloadLinks();
        },
      }));
    const {
      mutate: deleteReferralLinkMutation,
      error: deleteReferralLinkError,
    } = useMutation<{
      deleteReferralLink: boolean;
    }>(deleteReferralLink, () => ({
      update: (cache, { data }) => {
        reloadLinks();
      },
    }));
    return {
      deleteReferralLinkMutation,
      restaurants,
      referralLinks,
      referralLinksLoading,
      referralLinksError,
      createReferralLinkError,
      newReferralLink,
    };
  },
};
</script>

<style scoped>
.referrals {
  padding: 20px;
}

.referrals h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.referrals ul {
  list-style-type: none;
  padding: 0;
}

.referrals li {
  padding: 5px 0;
}
</style>
