import { gql } from '@apollo/client/core';
import { markRaw } from 'vue';

export const getRestaurants = markRaw(gql`
  {
    getRestaurants {
      address
      city {
        currency
        id
        name
        name_ru
      }
      cityId
      description
      description_ru
      id
      name
      name_ru
    }
  }
`);
