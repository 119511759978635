<template>
  <div>
    <div class="info-block">
      <h1>
        {{
          $t('order.page_title', { threadId: orderQuery?.getOrder.threadId })
        }}
      </h1>
      <div v-if="error">
        {{ $t('system.error', { message: error.message }) }}
      </div>
      <div v-if="loading">{{ $t('system.loading') }}</div>
      <div v-if="orderQuery?.getOrder">
        <b-row>
          <b-col>
            <div class="form-group">
              <label for="name">{{ $t('order.customer.header') }} </label>
              <div class="customer-info"></div>
              <p>
                <strong>{{ $t('order.customer.name') }}:</strong>
                {{ orderQuery.getOrder.user.name }}
              </p>
              <p>
                <strong>{{ $t('order.customer.address') }}:</strong>
                {{ orderQuery.getOrder.user.address }}
              </p>
              <p>
                <strong>{{ $t('order.customer.addressAdditions') }}:</strong>
                {{ orderQuery.getOrder.user.additionalAddress }}
              </p>
              <p>
                <strong>{{ $t('order.customer.contact') }}:</strong>
                {{ orderQuery.getOrder.user.contactType }}:
                {{ orderQuery.getOrder.user.phone }}
              </p>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <label for="restaurant"
                >{{ $t('order.restaurant.header') }}
              </label>
              <div class="restaurant-info"></div>
              <p>
                <strong>{{ $t('order.restaurant.name') }}:</strong>
                {{ orderQuery.getOrder.restaurant.name }}
              </p>
              <p>
                <strong>{{ $t('order.restaurant.address') }}: </strong>
                <a :href="orderQuery.getOrder.restaurant.address">{{
                  orderQuery.getOrder.restaurant.address
                }}</a>
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="form-group">
              <strong>{{ $t('order.items.header') }}:</strong>
              <ol>
                <li v-for="item in orderQuery.getOrder.items" :key="item.id">
                  {{ item.dish.name }} - {{ item.dish.price }}
                  {{ orderQuery.getOrder.city.currency }} x {{ item.count }} =
                  {{ item.sum }} {{ orderQuery.getOrder.city.currency }}
                </li>
              </ol>
              <p>
                <strong>{{ $t('order.items.sum') }}:</strong>
                {{ orderQuery.getOrder.sum }}
                {{ orderQuery.getOrder.city.currency }}
              </p>
              <p>
                <strong>{{ $t('order.items.delivery') }}:</strong>
                {{ orderQuery.getOrder.deliveryCost }}
                {{ orderQuery.getOrder.city.currency }}
              </p>
              <p>
                <strong>{{ $t('order.items.discount') }}:</strong>
                {{ orderQuery.getOrder.discount }}
                {{ orderQuery.getOrder.city.currency }}
              </p>
              <p>
                <strong>{{ $t('order.items.totalWithDelivery') }}:</strong>
                {{ orderQuery.getOrder.totalSum }}
                {{ orderQuery.getOrder.city.currency }}
              </p>
            </div>
          </b-col>
          <b-col>
            <div class="form-group">
              <strong>{{ $t('order.status') }}: </strong>
              {{
                $t(
                  `orders.statuses.${EOrderStatus[orderQuery.getOrder.status]}`,
                )
              }}
              <OrderStatusEditor
                :order="orderQuery.getOrder"
                @statusChanged="onStatusChanged"
              />
            </div>
            <div class="form-group">
              <strong>{{ $t('order.paymentMethod') }}: </strong>
              {{ EPaymentMethod[orderQuery.getOrder.paymentMethod] }}
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="info-block">
      <h2>{{ $t('order.chat.header') }}</h2>
      <div class="form-group">
        <label for="message">{{ $t('order.chat.message') }}</label>
        <textarea id="message" v-model="message" rows="4"></textarea>
      </div>
      <div class="form-group">
        <label for="url">{{ $t('order.chat.url') }}</label>
        <input type="text" id="url" v-model="url" />
      </div>
      <div class="form-group">
        <b-row>
          <b-col>
            <button @click="sendToClient">
              {{ $t('order.chat.sendToUser') }}
            </button>
          </b-col>
          <b-col>
            <button @click="sendToRestaurant">
              {{ $t('order.chat.sendToRestaurant') }}
            </button>
          </b-col>
          <b-col>
            <button @click="sendMessageAsAdmin">
              {{ $t('order.chat.sendAdminMessage') }}
            </button>
          </b-col>
        </b-row>
      </div>
      <div v-if="messagesQuery?.getOrderMessages">
        <div
          v-for="message in messagesQuery.getOrderMessages"
          :key="message.id"
          class="message"
        >
          <p class="message-text">
            <em>{{ format(new Date(message.createdAt), 'M/dd@HH:mm') }}</em
            >&nbsp; <strong>{{ message.from.name }}:</strong> {{ message.text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { EOrderStatus, EPaymentMethod, IChatMessage, IOrder } from 'types';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router/composables';
import { format } from 'date-fns';

import {
  getOrder,
  sendOrderMessage,
  updateOrder,
  sendAdminMessage,
  getOrderMessages,
} from '../graphql';

import OrderStatusEditor from './OrderStatusEditor.vue';

export default {
  components: {
    OrderStatusEditor,
  },
  data() {
    return {
      message: '',
      url: '',
      format,
      EOrderStatus,
      EPaymentMethod,
    };
  },
  methods: {
    onStatusChanged(data: { status: EOrderStatus }) {
      this.updateStatus({
        id: this.data.getOrder.id,
        status: data.status,
      });
    },
    sendToClient() {
      this.sendMessage({
        threadId: this.data.getOrder.threadId,
        text: this.message,
        direction: 'TO_USER',
      });
    },
    sendToRestaurant() {
      this.sendMessage({
        threadId: this.data.getOrder.threadId,
        text: this.message,
        direction: 'TO_RESTAURANT',
      });
    },
    sendMessageAsAdmin() {
      this.sendAdminMessageMutation({
        threadId: this.data.getOrder.threadId,
        text: this.message,
        url: this.url,
      }).catch((e) => {
        this.$toast.error(this.$t('system.error', { message: e.message }));
      });
    },
  },
  setup() {
    const route = useRoute();
    const {
      result: orderQuery,
      loading: orderLoading,
      error: orderError,
      refetch: reloadOrder,
    } = useQuery<{ getOrder: IOrder }>(getOrder, {
      threadId: route.params.id,
    });
    const {
      result: messagesQuery,
      loading: messagesLoading,
      error: messagesError,
    } = useQuery<{ getOrderMessages: IChatMessage[] }>(getOrderMessages, {
      threadId: route.params.id,
    });
    const { mutate: updateStatus } = useMutation<{ updateOrder: IOrder }>(
      updateOrder,
      () => ({
        update: (cache, { data }) => {
          reloadOrder();
        },
      }),
    );
    const { mutate: sendMessage } = useMutation<{ sendOrderMessage: boolean }>(
      sendOrderMessage,
      () => ({
        update: (cache, { data }) => {
          reloadOrder();
        },
      }),
    );
    const { mutate: sendAdminMessageMutation } = useMutation<{
      sendAdminMessage: boolean;
    }>(sendAdminMessage, () => ({
      update: (cache, { data }) => {
        reloadOrder();
      },
    }));
    return {
      reloadOrder,
      updateStatus,
      sendMessage,
      sendAdminMessageMutation,
      orderQuery,
      messagesQuery,
      loading: orderLoading || messagesLoading,
      error: orderError || messagesError,
    };
  },
};
</script>

<style scoped>
.info-block {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.2em;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.message-text {
  margin: 0;
  size: 50%;
}
</style>
