<template>
  <b-collapse
    v-model="shown"
    @shown="updateMessagesList"
    class="chatbox w-100 bg-white"
    ref="chatbox"
  >
    <div
      class="w-100 d-flex justify-content-center align-items-center"
      v-if="isLoading"
    >
      <b-spinner />
    </div>

    <b-list-group v-if="messages.length > 0">
      <b-list-group-item
        v-for="message in messages"
        :key="message.id"
        class="d-flex justify-content-between align-items-center border-0 p-0 pt-1 pl-3"
      >
        <b-container fluid class="p-0">
          <b-row v-if="message.orderHasBeenChanged" class="mb-3">
            <b-col sm="12" class="text-center border-bottom pb-2 pt-2">
              <small>
                {{
                  $t('chat.orderHasBeenChanged', {
                    threadId: message.order.threadId,
                  })
                }}
              </small>
            </b-col>
          </b-row>

          <div class="chatbox__message d-flex">
            <div class="chatbox__message__content">
              <b-badge
                :variant="message.restaurantSent ? 'danger' : 'primary'"
                pill
              >
                {{
                  message.restaurantSent
                    ? message.order.restaurant.name
                    : message.from
                    ? message.from.name
                    : $t('chat.customer')
                }}
              </b-badge>
              <span>: {{ message.text }}</span>
            </div>

            <div class="chatbox__message__time ml-auto mr-2">
              <small>
                {{
                  new Date(message.createdAt).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                }}
              </small>
            </div>
          </div>
        </b-container>
      </b-list-group-item>
    </b-list-group>

    <div
      class="w-100 d-flex justify-content-center align-items-center"
      v-else-if="messages.length === 0 && !isLoading"
    >
      <b-icon icon="envelope-open-fill" class="mr-2" />
      {{ $t('chat.chatIsEmpty') }}
    </div>

    <b-form
      class="chatbox__input mt-3 border-top d-flex justify-content-between align-items-center w-100 bg-white"
      @submit.prevent="onSubmit"
    >
      <b-form-input
        class="border-0"
        :placeholder="$t('chat.inputPlaceholder')"
        v-model="textToSend"
      />

      <b-button class="ml-auto" variant="primary" type="submit" size="sm">
        <b-spinner v-if="isSending" small />
        {{ $t('chat.send') }}
      </b-button>
      <b-button
        class="ml-auto"
        variant="info"
        @click="sendToRestaurant()"
        size="sm"
      >
        <b-spinner v-if="isSending" small />
        {{ $t('chat.sendToRestaurant') }}
      </b-button>
    </b-form>
  </b-collapse>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IChatMessage } from 'types';

@Component({ name: 'Chat' })
export default class Chat extends Vue {
  @Prop([Number, String])
  threadId: number | string;

  @Prop(String)
  customerName: string;

  @Prop({ type: Boolean, default: false })
  shown: boolean;

  isLoading = true;
  messages: IChatMessage[] = [];
  textToSend = '';
  isSending = false;

  updateInterval = null;

  mounted() {
    /**
     * https://github.com/timetoeatio/timetoeatio/issues/370
     */

    const UPDATE_MESSAGE_HISTORY_INTERVAL = 60 * 1000;

    this.updateInterval = setInterval(() => {
      if (this.shown === false) return;

      if (this.$route.path !== '/orders') {
        clearInterval(this.updateInterval);
        return;
      }

      this.updateMessagesList();
    }, UPDATE_MESSAGE_HISTORY_INTERVAL);
  }

  updateMessagesList() {
    this.isLoading = true;
    this.messages.length = 0;

    this.axios
      .get<IChatMessage[]>('/orders/' + this.threadId)
      .then(({ data }) => {
        this.messages = data;
        this.isLoading = false;

        // For scrolling down after messages are loaded

        const chatBox = this.$refs.chatbox as Vue;
        //   setImmediate(() => {
        chatBox.$el.scrollTop = chatBox.$el.scrollHeight;
        //   });

        chatBox.$el.querySelector('input')?.focus();
      });
  }

  sendToRestaurant() {
    this.isSending = true;

    this.axios
      .post(`/messages/${this.threadId}/restaurant`, { text: this.textToSend })
      .then(() => {
        this.isSending = false;
        this.textToSend = '';
        this.updateMessagesList();
      });
  }

  onSubmit() {
    this.isSending = true;

    this.axios
      .post('/orders/' + this.threadId, { text: this.textToSend })
      .then(() => {
        this.isSending = false;
        this.textToSend = '';
        this.updateMessagesList();
      });
  }
}
</script>

<style scoped>
.chatbox {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.chatbox__input {
  position: sticky;
  bottom: 0;
  padding-bottom: 3px;
  padding-right: 6px;
}

input:focus {
  outline: none;
  box-shadow: none;
}
</style>
