<template>
  <div v-if="restaurants">
    <div class="d-block d-md-flex justify-content-between align-items-center">
      <h1 class="">{{ $t('restaurants.title') }}</h1>

      <b-button
        v-if="isAdmin || isCityAdmin"
        to="/restaurant/"
        variant="success"
      >
        {{ $t('restaurants.add_button') }}
      </b-button>
    </div>

    <div class="row-md-12 mb-3" v-if="roleId == RoleEnum.ADMIN">
      <label for="select-city">Choose the city</label>
      <b-form-select v-model="selectedCity" :options="cities">
        Please select a city
      </b-form-select>
    </div>

    <div class="row">
      <b-form-checkbox v-model="showDisabled" class="mb-3" switch inline>{{
        $t('restaurants.showDisabled')
      }}</b-form-checkbox>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-table :fields="fields" :items="getRestaurants" hover striped>
          <template v-slot:cell(name)="data">
            <b-link :to="`/restaurant/${data.item.id}`">
              {{ data.item.name }}
            </b-link>
          </template>

          <template v-slot:cell(enabled)="data">
            {{ data.item.enabled ? '✅' : '❌' }}
          </template>

          <template v-slot:cell(notificationUser)="data">
            <span v-if="data.item.notificationUser">
              <a
                v-if="data.item.notificationUser.username"
                :href="'https://t.me/' + data.item.notificationUser.username"
                target="_blank"
              >
                @{{ data.item.notificationUser.username }}
              </a>
              <span v-else>
                {{ data.item.notificationUser.name }}
              </span>
            </span>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ICity, IRestaurant, RoleEnum } from 'types';

export default {
  name: 'Restaurants',
  methods: {
    selected() {
      this.$router.push({ path: '/details/:recordId' });
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('restaurants.name'),
          sortable: true,
        },
        {
          key: 'name_ru',
          label: this.$t('restaurants.name_ru'),
          sortable: true,
        },
        {
          key: 'enabled',
          label: this.$t('restaurants.enabled'),
          sortable: true,
        },
        {
          key: 'position',
          label: this.$t('restaurants.position'),
          sortable: true,
        },
        {
          key: 'recentOrdersCount',
          label: this.$t('restaurants.recentOrdersCount'),
          sortable: true,
        },
        {
          key: 'minOrderSum',
          label: this.$t('restaurants.minOrderSum'),
          sortable: false,
        },
        {
          key: 'deliveryTime',
          label: this.$t('restaurants.deliveryTime'),
          sortable: false,
        },
        {
          key: 'notificationUser',
          label: this.$t('restaurants.notificationAccount'),
        },
      ],
      restaurants: [],
      cities: [] as ICity[],
      selectedCity: null,
      showDisabled: false,
      RoleEnum,
    };
  },
  computed: {
    getRestaurants() {
      return this.restaurants
        .slice()
        .filter((restaurant: IRestaurant) => {
          if (this.showDisabled) {
            return true;
          }

          return restaurant.enabled;
        })
        .sort(function (a: IRestaurant, b: IRestaurant) {
          if (a.recentOrdersCount !== b.recentOrdersCount) {
            return b.recentOrdersCount - a.recentOrdersCount;
          }

          if (a.position !== b.position) {
            return a.position - b.position;
          }

          return a.name.localeCompare(b.name);
        });
    },
    roleId() {
      return this.$store.getters.roleId;
    },
    cityId() {
      return this.$route.params.cityId as string;
    },
    isAdmin() {
      return this.$store.getters.roleId === RoleEnum.ADMIN;
    },
    isRestaurantOwner() {
      return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
    },
    isCityAdmin() {
      return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
    },
    isOperator() {
      return this.$store.getters.roleId === RoleEnum.OPERATOR;
    },
  },
  watch: {
    selectedCity(city: ICity) {
      if (!city) {
        return;
      }
      if (this.cityId !== city.id) {
        this.$router.push({ path: `/restaurants/${city.id}` });
      }
      this.axios
        .get(`/restaurants/city/${this.cityId}`)
        .then(({ data }: { data: IRestaurant[] }) => {
          this.restaurants = data;
        });
    },
  },
  mounted() {
    this.axios.get('/cities').then(({ data }: { data: ICity[] }) => {
      this.cities = data.map((city) => ({
        value: city,
        text: city.name,
      }));
      if (this.cityId) {
        this.selectedCity = data.find((city) => city.id === this.cityId);
      }
    });
  },
};
</script>

<style scoped></style>
