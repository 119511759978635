import { gql } from '@apollo/client/core';
import { markRaw } from 'vue';

export const sendOrderMessage = markRaw(gql`
  mutation ($threadId: String!, $text: String!, $direction: MessageDirection!) {
    sendOrderMessage(threadId: $threadId, text: $text, direction: $direction) {
      id
      orderId
      fromId
      toId
      text
      status
    }
  }
`);

export const sendAdminMessage = markRaw(gql`
  mutation ($threadId: String!, $text: String!, $url: String) {
    sendAdminMessage(threadId: $threadId, text: $text, url: $url) {
      text
      caption
      message_id
      date
      chat {
        id
        type
        first_name
      }
    }
  }
`);

export const getOrderMessages = markRaw(gql`
  query Query($threadId: String!, $limit: Int) {
    getOrderMessages(limit: $limit, threadId: $threadId) {
      fromId
      from {
        name
      }
      id
      orderId
      status
      text
      toId
      to {
        name
      }
      createdAt
    }
  }
`);
